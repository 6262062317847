<template>
  <div class="questionnaire-sub-fields">
    <div
      class="options"
      v-for="(option, index) in field.options"
      :key="`option-${index}`"
    >
      <div
        class="rule"
        v-for="(rule, ruleIndex) in option.rules"
        :key="`rule-${ruleIndex}`"
      >
        <QuestionnaireField
          v-if="rule.field != null"
          v-show="field.value === option.value"
          :field="rule.field"
          :disabled="disabled"
        ></QuestionnaireField>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import QuestionnaireField from './questionnaire_field'
export default {
  name: 'QuestionnaireSubFields',
  components: { QuestionnaireField },
  props: {
    field: {
      type: Object,
      default: () => ({ value: null }),
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
